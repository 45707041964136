.MuiTablePagination-root {
  position: fixed;
}

.MuiTablePagination-toolbar {
  padding-left: 0px;
}

.MuiSnackbar-root {
  top: 8px !important;
}

@media (min-width: 600px) {
  .css-1arijsr-MuiSnackbar-root {
      top: 8px;
  }
}